import {Autocomplete, TextField} from "@mui/material";
import React from "react";

export type SimpleAutoCompleteProps = {
    id: string;
    label: string;
    options: string[];
    value: string;
    setValue: (value: string) => void;
    onBlur?: () => void;
    error?: boolean;
    errorMessage?: string;
    disabled?: boolean;
    required?: boolean;
    style: React.CSSProperties;
    outlined?: boolean;
}

const SimpleAutoComplete: React.FC<SimpleAutoCompleteProps> = (props: SimpleAutoCompleteProps) => {
    const [inputValue, setInputValue] = React.useState('');
    const useOutlined = props.outlined ?? true; //if null, default to true
    const variant = useOutlined ? "outlined" : "standard";
    const isRequired = props.required ?? false;

    const getOptionsWithEmpty = (options : string[]) => {
        if(options.includes("")) {
            return options;
        } else {
            return ["", ...options];
        }
    }

    const containsPartialMatch = (input: string, options: string[]): string | undefined => {
        if (input.length === 0) return undefined;
        return options.find(option => option.includes(input));
    };

    return (
        <Autocomplete
            id={props.id}
            disabled={props.disabled ?? false}
            style={props.style}
            options={getOptionsWithEmpty(props.options)}
            onKeyDown={(event) => {
                switch (event.key) {
                    case "Tab": {
                        const partialMatch = containsPartialMatch(inputValue, props.options); //note that a perfect match is also a partial match ;)
                        if (inputValue.length > 0 && partialMatch) {
                            props.setValue(partialMatch);
                        } else {
                            props.setValue("");
                        }
                        break;
                    }
                    default:
                }
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            value={props.value}
            onChange={(event, value) => {
                const target = value ?? "";

                if (target.length > 0 && containsPartialMatch(target, props.options)) {
                    props.setValue(target);
                } else {
                    props.setValue("");
                }
            }}
            onBlur={props.onBlur}
            renderInput={(params) => <TextField {...params}
                                                                    variant={variant}
                                                                    error={props.error ?? false}
                                                                    helperText={props.errorMessage}
                                                                    required={isRequired}
                                                                    label={props.label} />}
        />
    );

};

export default SimpleAutoComplete;