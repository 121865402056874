import {Accordion, AccordionDetails, AccordionSummary, Checkbox, FormControlLabel, Typography} from "@mui/material";
import ScrollPane from "../../guiComponents/ScrollPane";
import React from "react";
import {CreditApplicationData} from "./CreditApplicationData";
import {ExpandMore} from "@mui/icons-material";

export type CreditApplicationTermsProps = {
    creditApplicationData: CreditApplicationData;
    directDataUpdate: (fieldName: string, value: any) => void;
}

const CreditApplicationTerms = (props: CreditApplicationTermsProps) => {
    return <>
            <Accordion style={{width: "100%"}} >
                <AccordionSummary expandIcon={<ExpandMore/>}  >
                    <Typography variant={"subtitle1"} style={{fontWeight: 'bold'}}>Standard Terms And Conditions</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <ScrollPane height={"300px"}>
                        <Typography variant={"h6"}>TERMS AND CONDITIONS</Typography>
                        <Typography className="body-paragraph-header" variant={"subtitle1"}>Bank Authorization</Typography>
                        <Typography variant={"body2"}>
                            I (we) hereby authorize Treesource L.L.C. or its agent(s) to request and receive credit information regarding me (us) from the bank and
                            references furnished in this application and from any other source deemed reliable by Treesource L.L.C. and I (we) waive any rights or actions
                            which may occur under the Federal Privacy Act or any other law or statue of the United States or the State of Idaho.
                        </Typography>
                        <Typography className="body-paragraph-header" variant={"subtitle1"}>Application Agreement</Typography>
                        <Typography variant={"body2"}>
                            The undersigned certifies under the penalty of perjury that the information and statements in this application are true and complete and are made
                            for the purpose of inducing Treesource L.L.C.to establish an open account line of credit and that the seller intends to rely on all of the information
                            presented in this application in determining its creditworthiness. In consideration of and in order to establish an open account line of credit, the
                            undersigned promises to pay for all purchases in accordance with the terms of sale set by Treesource L.L.C. Payment of invoices billed is due
                            no later than the 30 th day following the purchase unless otherwise explicitly authorized in writing by Treesource L.L.C. For the consideration of
                            extension of credit, the undersigned promises to pay to the order of Treesource L.L.C. all service charges on or before the due date of each month
                            following the date of any and all charges. In the event said account becomes past-due pursuant to the terms of sale, the undersigned agrees to pay
                            interest to Treesource L.L.C.at the rate of one percent (1.5%) per month or eighteen percent (18%) per annum. All invoices are due per the
                            terms of sale of Treesource LLC. All amounts for purchases from Treesource LLC are payable to the address of: 20851 Bingham Street, Rupert, ID
                            83350.The undersigned agrees that if payment on said account is not made on or before the due date and the account is placed in the hands of an
                            attorney for collection, or suit is brought on same, or same is collected through probate or bankruptcy proceedings, the undersigned shall be
                            liable for all costs of collection including reasonable attorney’s fees. The undersigned further agrees that if suit is brought, the venue for any
                            litigation shall be Idaho County, Idaho. Treesource reserves the right, but not the obligation, to net monies due from the undersigned applicant
                            including freight or transportation charges. The parties hereto knowingly and intentionally waive the right to a jury trial on any issue or dispute
                            that may arise between them. Applicants do hereby expressly and irrevocably waive any notice and/or hearing, which may be required for
                            prejudgment remedies under the statue of the state of Idaho. The undersigned also agrees that notification must be given to Treesource LLC in
                            writing, and by certified mail of any change in ownership, the business name or the business structure under which credit is established. The
                            undersigned hereby authorizes Treesource to execute and file on behalf of the undersigned any such UCC financing and continuation
                            statements as Treesource deems necessary to perfect its, and/or its Assignee’s security interest in any purchases made to the undersigned’s
                            account. The person executing this agreement has authority to bind the customer and is authorized by the customer to enter into the credit
                            application terms and conditions. I (We) certify that this request is for the extension of credit for business purposes only and is not intended for the
                            extension of credit for personal, family or household purposes.
                        </Typography>
                        <Typography className="body-paragraph-header" variant={"subtitle1"}>Equal Opportunity</Typography>
                        <Typography variant={"body2"}>
                            The Federal Equal Opportunity Credit Act prohibits creditors from discriminating against credit applicants on the basis of race, color, religion,
                            national origin, sex, marital status, age (provided the applicant has the capacity to enter into a binding contract); because all or part of the
                            applicant’s income devices from any public assistance program; or because the applicant has in god faith exercised any right under the Consumer
                            Credit Protection Act. The federal agency that administers compliance with this law concerning this creditor is the Federal Trade Commission,
                            Division of Credit Practices, 6 th Street and Pennsylvania Avenue, NW, Washington, DC 20580.
                        </Typography>
                    </ScrollPane>
                    <FormControlLabel
                        style={{marginTop: 10, marginLeft: 0}}
                        control={<Checkbox checked={props.creditApplicationData.hasAcceptedStandardTerms}
                                           onChange={(event) => props.directDataUpdate("hasAcceptedStandardTerms", event.target.checked)}/>}
                        label="I agree to the standard terms and conditions"
                    />
                </AccordionDetails>
            </Accordion>
    </>
}

export default CreditApplicationTerms;