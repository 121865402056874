import {
  Button,
  TextField,
  Typography,
  Card,
  AlertColor,
  Container,
  Snackbar,
  Alert,
  CardHeader,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";
import Axios from "axios";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../providers/AuthProvider";
import { StylesContext } from "../../providers/StylesProvider";
import TreeSourceLoad from "../Loaders/TreeSourceLoad";
import { WebsiteSettings } from "../../types/WebsiteSettings";
import { DatePicker } from "@mui/x-date-pickers";
import SalesRepInfo from "../guiComponents/SalesRepInfo";

const PREFIX = "ContactUs";

const classes = {
  parent: `${PREFIX}-parent`,
  leftSide: `${PREFIX}-leftSide`,
  rightSide: `${PREFIX}-rightSide`,
  form: `${PREFIX}-form`,
  inputs: `${PREFIX}-inputs`,
  textGroup: `${PREFIX}-textGroup`,
};

const Root = styled("div")({
  [`& .${classes.parent}`]: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap-reverse",
    justifyContent: "space-evenly",
  },
  [`& .${classes.leftSide}`]: {
    width: "100%",
    minWidth: 250,
  },
  [`& .${classes.rightSide}`]: {
    width: "100%",
    minWidth: 300,
  },
  [`& .${classes.form}`]: {
    display: "flex",
    flexDirection: "column",
  },
  [`& .${classes.inputs}`]: {
    margin: 10,
  },
  [`& .${classes.textGroup}`]: {
    display: "flex",
    flexDirection: "row",
    flexFlow: "nowrap",
    alignItems: "center",
  },
});

const ContactUs = () => {
  const Auth = useContext(AuthContext);
  const { navBarHeight, isDesktop } = useContext(StylesContext);

  const [type, setType] = useState("");
  const [customerType, setCustomerType] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [state, setState] = useState("");
  const [message, setMessage] = useState("");
  const [city, setCity] = useState("");
  const [jobDate, setJobDate] = useState(null);
  const [messageHint, setMessageHint] = useState("");
  const [fullDetailForm, setFullDetailForm] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastSeverity, setToastSeverity] = useState<AlertColor | undefined>();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [websiteProperties, setWebsiteProperties] = useState<WebsiteSettings>();

  const states = [
    "AK",
    "AL",
    "AR",
    "AS",
    "AZ",
    "CA",
    "CO",
    "CT",
    "DC",
    "DE",
    "FL",
    "GA",
    "GU",
    "HI",
    "IA",
    "ID",
    "IL",
    "IN",
    "KS",
    "KY",
    "LA",
    "MA",
    "MD",
    "ME",
    "MI",
    "MN",
    "MO",
    "MS",
    "MT",
    "NC",
    "ND",
    "NE",
    "NH",
    "NJ",
    "NM",
    "NV",
    "NY",
    "OH",
    "OK",
    "OR",
    "PA",
    "PR",
    "RI",
    "SC",
    "SD",
    "TN",
    "TX",
    "UT",
    "VA",
    "VI",
    "VT",
    "WA",
    "WI",
    "WV",
    "WY",
  ];

  const getApplicationSettings = async () => {
    try {
      let res = await Axios.get("/api/applicationsettings");
      setWebsiteProperties(res.data);
    } catch (error) {}
  };

  useEffect(() => {
    getApplicationSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Auth.loggedOutLocation, Auth.selectedCustomer]);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    window.scrollTo(0, 0);

    setLoading(true);
    try {
      const config = {
        headers: {
          authorization: localStorage.getItem("token"),
          Accept: "application/json",
          "Content-Type": "application/json",
          "Cache-Control": "no-store",
        },
      };
      await Axios.post(
        `/api/sendemail/contactform`,
        { type, customerType, state, city, jobDate, name, email, message },
        config
      );

      setType("");
      setCustomerType("");
      setState("");
      setCity("");
      setJobDate(null);
      setName("");
      setEmail("");
      setMessage("");
      setLoading(false);

      setToastMessage("Your message has been sent successfully!");
      setToastSeverity("success");
      setFullDetailForm(false);
      setOpen(true);
    } catch (err) {
      setToastMessage("Message did not send, please try again later");
      setToastSeverity("error");
      setOpen(true);
      setLoading(false);
    }
  };

  const renderToast = () => {
    return (
      <Snackbar open={open}>
        <Alert
          onClose={handleClose}
          severity={toastSeverity}
          style={{ width: "100%" }}
        >
          {toastMessage}
        </Alert>
      </Snackbar>
    );
  };

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const contactForm = () => {
    return (
      <div id="leftSide" className={classes.leftSide}>
        <form onSubmit={handleSubmit} className={classes.form}>
          <FormControl required variant="outlined" style={{ margin: "10px" }}>
            <InputLabel id="type-label">Type</InputLabel>
            <Select
              autoFocus
              labelId="type-label"
              id="type-select"
              value={type}
              onChange={(e) => {
                setType(e.target.value);

                if (e.target.value === "Sales") {
                  setFullDetailForm(true);
                  setMessageHint("Please include item names and quantities.");
                } else {
                  setFullDetailForm(false);
                  setMessageHint("");
                }
              }}
              label="Type"
            >
              <MenuItem value={"Sales"}>Sales Inquiry</MenuItem>
              <MenuItem value={"Support"}>Support Request</MenuItem>
              <MenuItem value={"Other"}>Other</MenuItem>
            </Select>
          </FormControl>
          {fullDetailForm ? (
            <>
              <FormControl
                required
                variant="outlined"
                style={{ margin: "10px" }}
              >
                <InputLabel id="type-label">Customer Type</InputLabel>
                <Select
                  autoFocus
                  labelId="customer-type-label"
                  id="customer-type-select"
                  value={customerType}
                  onChange={(e) => setCustomerType(e.target.value)}
                  label="CustomerType"
                >
                  <MenuItem value={"Contractor"}>Contractor</MenuItem>
                  <MenuItem value={"Homeowner"}>Homeowner</MenuItem>
                  <MenuItem value={"Other"}>Other</MenuItem>
                </Select>
              </FormControl>

              <div style={{ display: "flex", flexDirection: "row" }}>
                <TextField
                  style={{ flexGrow: 1 }}
                  variant="outlined"
                  required
                  label="State"
                  name="region"
                  defaultValue="ID"
                  onChange={(e) => setState(e.target.value)}
                  value={state}
                  className={classes.inputs}
                  select
                >
                  {states.map((nextState) => {
                    return (
                      <MenuItem key={nextState} value={nextState}>
                        {nextState}
                      </MenuItem>
                    );
                  })}
                </TextField>

                <TextField
                  style={{ flexGrow: 1 }}
                  variant="outlined"
                  required
                  name="City"
                  label="City"
                  onChange={(e) => setCity(e.target.value)}
                  value={city}
                  className={classes.inputs}
                ></TextField>
              </div>

              <DatePicker
                className={classes.inputs}
                onChange={(e: any) => setJobDate(e!)}
                value={jobDate}
                label="Apx. Job Date"
                // @ts-ignore
                slotProps={{
                  // @ts-ignore
                  datePicker: {
                    required: true,
                  },
                }}
              />
            </>
          ) : (
            ""
          )}

          <TextField
            variant="outlined"
            required
            name="Name"
            label="Name"
            onChange={(e) => setName(e.target.value)}
            value={name}
            className={classes.inputs}
          ></TextField>

          <TextField
            required
            variant="outlined"
            name="Email"
            label="Email"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            className={classes.inputs}
          ></TextField>
          <TextField
            required
            variant="outlined"
            name="Message"
            label="Message"
            helperText={messageHint}
            onChange={(e) => setMessage(e.target.value)}
            value={message}
            multiline
            rows={4}
            className={classes.inputs}
          ></TextField>

          <Button
            variant="contained"
            color="primary"
            className={classes.inputs}
            type="submit"
          >
            Submit
          </Button>
        </form>
      </div>
    );
  };

  if (loading) {
    return <TreeSourceLoad message="Submitting Message" />;
  }

  return (
    <Root style={{ paddingTop: navBarHeight }}>
      <Container disableGutters={!isDesktop}>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container direction={"row"}>
            <Grid container item xs={12} lg={6} direction="column">
              <div>
                <SalesRepInfo/>
              </div>

              <div style={{ padding: 10 }}>
                <Card className={classes.leftSide}>
                  <CardHeader title="Contact Us" />
                  {contactForm()}
                </Card>
              </div>
            </Grid>
            <Grid container item xs={12} lg={6} direction="column">
              <div style={{ padding: 10 }}>
                <Card>
                  {websiteProperties?.entityWebsiteSettings
                    ?.filter((x) => x.entityName !== "Tree Source")
                    .map((x) => {
                      return (
                        <div
                          style={{
                            padding: "10px",
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            flexDirection: "column",
                          }}
                        >
                          <Typography>
                            <strong>{x.entityName}</strong>
                          </Typography>
                          <Typography>{x.address}</Typography>
                          <Typography>{x.phoneNumber}</Typography>
                          <Button
                            onClick={() =>
                              window.open(x.directionsLink, "_blank")
                            }
                          >
                            Get Directions
                          </Button>
                        </div>
                      );
                    })}
                </Card>
              </div>
            </Grid>
          </Grid>
        </Box>
        {renderToast()}
        <div style={{margin: 55}}></div>
      </Container>
    </Root>
  );
};

export default ContactUs;
