import {camelCaseToSplitWords, FIELD_TYPE, ValidationRule} from "./ValidationRule";

class FieldLengthRule implements ValidationRule {
    type = FIELD_TYPE;

    maxLength: number;

    constructor(maxLength: number) {
        this.maxLength = maxLength;
    }

    isValid(field: any)  {
        return field.length <= this.maxLength;
    }

    getErrorMessage(fieldName: string) {
        const name = camelCaseToSplitWords(fieldName);
        return `${name} cannot be longer than ${this.maxLength} characters`;
    }
}

export default FieldLengthRule;